import React from "react"
import { Link } from "gatsby"

const PostsList = ({ posts }) => (
  <ol className="list ma0 pa0 mw-reading center">
    {posts.map(post => (
      <PostItem key={post.fields.slug} post={post} />
    ))}
  </ol>
)

const PostItem = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  return (
    <li className="mb5">
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <p className="c-secondary ma0">{post.frontmatter.date}</p>
          <h2 className="mt1 mb3">
            <Link className="link" to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
        </header>
        <section>
          <p
            className="c-secondary"
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp="description"
          />
        </section>
      </article>
    </li>
  )
}

export default PostsList
