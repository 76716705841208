/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          description
          author {
            name
            summary
          }
          mainUrl
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const mainUrl = data.site.siteMetadata?.mainUrl

  return (
    <div className="mw-reading center mv5">
      <p style={{ whiteSpace: "break-spaces" }}>
        <a href={mainUrl}>Estruto</a> {author.summary}
      </p>
      <ul className="list pa0">
        <li>
          <a href="https://www.linkedin.com/in/guilherme-stoll-toaldo-4951767b/">
            Guilherme Stoll Toaldo
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/rodrigo-rahal-31752872/">
            Rodrigo Rahal
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Bio
